import { paymentMethod } from "constant";
import React, { useEffect, useState, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Icofont from "react-icofont";
import { Link, withRouter } from "react-router-dom";
import { useRecoilState } from "recoil";
import { orderService } from "services";
import { orderDetailState } from "store/order";
import CartDropdownItem from "./cart/CartDropdownItem";
import Loading from "./common/Loading";

const TrackOrder = ({ match }) => {
  const orderId = match.params.orderId;
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useRecoilState(orderDetailState);
  const order = useMemo(() => {
    return orderDetails[orderId] || { items: [], location: {}, payment: {} };
  }, [orderDetails]);

  async function fetchData() {
    try {
      setLoading(true);
      const data = await orderService.getOrderById(orderId);
      setOrderDetails({ ...data, ...orderDetails });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="section bg-white osahan-track-order-page position-relative">
      <iframe
        title="Address"
        className="position-absolute"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2749.4440382623716!2d79.91109244051613!3d6.9054606158044844!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae259fa5863aa65%3A0xe33d2d1f01284b9b!2sWaters%20Edge%20-%20Sri%20Lanka!5e0!3m2!1sen!2slk!4v1686093917886!5m2!1sen!2slk"
        width="100%"
        height="676"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      />
      <Container className="pt-5 pb-5">
        <Row className="d-flex align-items-center">
          <Col md={6} className="text-center pb-4">
            <div className="osahan-point mx-auto"></div>
          </Col>
          <Col md={6}>
            <div className="bg-white p-4 shadow-lg mb-2">
              {loading ? (
                <Row>
                  <Col md={5}>
                    <Loading />
                  </Col>
                </Row>
              ) : (
                <div>
                  <div className="mb-2">
                    <small>
                      Order #{order.order_id}
                      <Link className="float-right font-weight-bold" to="#">
                        <Icofont icon="headphone-alt" /> HELP
                      </Link>
                    </small>
                  </div>
                  <h6 className="mb-1 mt-1">
                    {/* <Link to="/detail" className="text-black">Spice Hut Restaurant
							  </Link> */}
                  </h6>
                  <p className="text-gray mb-0">
                    <Icofont icon="clock-time" /> {order.time_slot} |{" "}
                    {order.items.length} Items | Rs. {order.total}
                  </p>
                </div>
              )}
            </div>

            <div className="bg-white p-4 shadow-lg">
              {loading ? (
                <Row>
                  <Col md={5}>
                    <Loading />
                  </Col>
                </Row>
              ) : (
                <div className="osahan-track-order-detail po">
                  <h5 className="mt-0 mb-3">Order Details</h5>
                  <Row>
                    <Col md={5}>
                      <small>FROM</small>
                      <h6 className="mb-1 mt-1">
                        <Link to="/detail" className="text-black">
                          <Icofont icon="food-cart" /> Waters Edge
                        </Link>
                      </h6>
                      <p className="text-gray mb-5">
                        316, ETHUL KOTTE ROAD, BATTARAMULLA
                      </p>
                      <small>DELIVER TO</small>
                      <h6 className="mb-1 mt-1">
                        <span className="text-black">
                          <Icofont icon="map-pins" /> {order?.location?.tag || 'PICKUP AT BAKERY'}
                        </span>
                      </h6>
                      {order.location && (
                        <p className="text-gray mb-0">
                          {order.location?.address_line_1},
                          {order.location?.address_line_2}
                        </p>
                      )}

                    </Col>
                    <Col md={7}>
                      <div className="mb-2">
                        <small>
                          <Icofont icon="list" /> {order.items?.length} ITEMS
                        </small>
                      </div>
                      {order.items.map((item, index) => {
                        return (
                          <CartDropdownItem
                            key={index}
                            id={item.id}
                            icoIcon="ui-press"
                            iconClass="text-success food-item"
                            title={`${item.product?.name} x ${item.quantity}`}
                            price={item.total}
                            optionName={item.product_option?.name}
                            potionName={item.product_portion?.name}
                          />
                        );
                      })}

                      <hr />
                      <p className="mb-1">
                        Delivery Fee
                        <span className="float-right text-dark">
                          Rs. {order.delivery_fee}
                        </span>
                      </p>
                      <p className="mb-1">
                        Discount
                        <span className="float-right text-dark">
                        Rs. {order.discount}
                        </span>
                      </p>
                      <hr />
                      <p className="mb-0 font-weight-bold text-black">
                        TOTAL BILL{" "}
                        <span className="float-right text-secondary">
                          Rs. {order.total}
                        </span>
                      </p>
                      <p className="mb-0 text-info">
                        <small>
                          Payment:{" "}
                          {order?.payment_method
                            ? paymentMethod[order?.payment_method].label
                            : ""}
                          {/* <span className="float-right text-danger">
                            Rs.620 OFF
                          </span> */}
                        </small>
                      </p>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            {/* <div className="bg-white p-4 shadow-lg mt-2">
              {loading ? (
                <Row>
                  <Col md={5}>
                    <Loading />
                  </Col>
                </Row>
              ) : (
                <Row className="text-center">
                  <Col>
                    <Icofont icon="tasks" className="icofont-3x text-info" />
                    <p className="mt-1 font-weight-bold text-dark mb-0">
                      Order Received
                    </p>
                    <small className="text-info mb-0">NOW</small>
                  </Col>
                  <Col>
                    <Icofont
                      icon="check-circled"
                      className="icofont-3x text-success"
                    />
                    <p className="mt-1 font-weight-bold text-dark mb-0">
                      Order Confirmed
                    </p>
                    <small className="text-success mb-0">NEXT</small>
                  </Col>
                  <Col>
                    <Icofont
                      icon="delivery-time"
                      className="icofont-3x text-primary"
                    />
                    <p className="mt-1 font-weight-bold text-dark mb-0">
                      Order Picked Up
                    </p>
                    <small className="text-primary mb-0">
                      LATER (ET : 30min)
                    </small>
                  </Col>
                </Row>
              )}
            </div> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default withRouter(TrackOrder);
