import apiHandler from "./apiHandler";


const getSettings = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("settings/search", data);
    return response.data.data.results;
  } catch (error) {
    return null;
  }
};

const getBanners = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("events/search", data);
    return response.data.data.results;
  } catch (error) {
    return null;
  }
};

const getTerms = async (
  data = {
    filter: [],
    limit: 10,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("terms/search", data);
    return response.data.data.results;
  } catch (error) {
    return null;
  }
};

export default {
  getSettings,
  getBanners,
  getTerms,
};
