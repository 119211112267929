import React from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { authState } from "store/user";

const IsLoggedIn = (Component) => {
  const isAuthenticated = useRecoilValue(authState);
  return (props) => {
    if (isAuthenticated) {
      return <Component {...props} />;
    } else {
      // return <Redirect to="/login" />;
    }
  };
};

const IsPublic = (Component) => {
  const isAuthenticated = useRecoilValue(authState);
  return (props) => {
    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return <Component {...props} />;
    }
  };
};

export default {
  IsLoggedIn,
  IsPublic,
};
