import React, { useEffect, useState } from "react";
import { Row, Col, Container, Image, Card } from "react-bootstrap";
import { orderService } from "services";
import { useRecoilState } from "recoil";
import { eventDetailState } from "store/order";
import { Link } from 'react-router-dom';
import { STORAGE_PATH } from "../constant"
import QRCode from "react-qr-code";

const Ord = ({ match }) => {
    const [eventInfo, setEventInfo] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const eventId = match.params.orderId;

    async function fetchData() {
        setIsLoading(true)
        Promise.all([
            orderService.getBookingById(eventId),
        ]).then((data) => {
            setIsLoading(false)
            if (data && data[0]) {
                if (data[0] && data[0].payment_status == "APPROVED") {
                    setEventInfo(data[0])
                }
            }

        }).catch((err) => {
            setIsLoading(false)
            setEventInfo(null);
        });
    }
    useEffect(() => {
        fetchData();
    }, [eventId]);


    return (
        <>
            {!isLoading && (<>
                {eventInfo == null ? (
                    <section className="section pt-5 pb-5 osahan-not-found-page">
                        <Container>
                            <Row>
                                <Col md={12} className="text-center pt-5 pb-5">
                                    {/* <Image className="img-fluid" src="/img/404.png" alt="404" /> */}
                                    <h1 className="mt-2 mb-2">Page not found</h1>
                                    <p>Uh-oh! Looks like the page you are trying to access, doesn't <br />exist. Please start afresh.</p>
                                    <Link className="btn btn-primary btn-lg" to="/">GO HOME</Link>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                ) : (
                    <>
                        <section className="section pb-2 ">

                            {eventInfo?.event?.event_image && eventInfo?.event?.event_image !== '' && (
                                <Image className="img-fluid" src={STORAGE_PATH + "/" + eventInfo?.event?.event_image} alt={eventInfo?.event?.title} />
                            )}

                            <Container>
                                <Row className="pt-4">

                                    <Col md={12}>
                                        <Card className="offer-card-horizontal border-0 shadow-sm mb-4">

                                            <Row className="">
                                                <Col md={6}>
                                                    <div className="card-body">
                                                        <h5 className="card-title">QR
                                                        </h5>

                                                        <QRCode value={eventInfo?.order_id} />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="card-body">
                                                        <h5 className="card-title">Order & Customer Information
                                                        </h5>
                                                        <p className="custom-para">
                                                            <span className="li-span">Order Id </span> :  {eventInfo?.order_id}
                                                        </p>
                                                        <p className="custom-para">
                                                            <span className="li-span">Event Date </span> :  {eventInfo?.event_date}
                                                        </p>
                                                        <p className="custom-para">
                                                            <span className="li-span">Payment Status </span>: {eventInfo?.payment_status == "APPROVED" ? (<span className="li-span-success">APPROVED</span>) : (<span className="li-span-failed">FAILED</span>)}

                                                        </p>

                                                        <p className="custom-para">
                                                            <span className="li-span">Name </span> :  {eventInfo?.cus_name}
                                                        </p>
                                                        <p className="custom-para">
                                                            <span className="li-span">Phone </span>:  {eventInfo?.cus_phone}
                                                        </p>
                                                        <p className="custom-para">
                                                            <span className="li-span">Email </span>: {eventInfo?.cus_email}
                                                        </p>
                                                        <p className="custom-para">
                                                            <span className="li-span">NIC </span>: {eventInfo?.cus_nic}
                                                        </p>


                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card >
                                    </Col>
                                    <Col md={12}>
                                        <Card className="offer-card-horizontal border-0 shadow-sm mb-4">
                                            <div className="card-body">
                                                <h5 className="card-title">How To Use This E-Pass
                                                </h5>
                                                <p className="custom-para">
                                                    <span className="li-span">1. </span> Arrive at the venue and proceed to the <span style={{ color: "#00FF00" }}>Green Channel Express</span>
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">2. </span> Present either a Email or show the SMS received from us to the counters
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">3. </span> Collect your physical buffet pass(s)
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">4. </span> Collect your wrist band(s)
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">5. </span> Proceed to festival like a pro...
                                                </p>

                                                <h5 className="card-title">Terms & Conditions
                                                </h5>
                                                <p className="custom-para">
                                                    <span className="li-span">1. </span> All group physical passes under single order number will be issued at the first point of redemption
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">2. </span> The Buffet Pass is valid only on the date specified
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">3. </span> You must present your voucher and exchange it for a physical pass(s)
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">4. </span> No table reservations
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">5. </span> Buffet Passes purchased are non-refundable and non-transferable to other days
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">6. </span> For Any inquiries pls email tickets@watersedge.lk or call the above mobile numbers
                                                </p>
                                                <p className="custom-para">
                                                    <span className="li-span">7. </span> In-appropriate behavior or language will result in the guest/s being removed from the premises
                                                </p>
                                            </div>
                                        </Card >
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </>
                )}



            </>)}
        </>

    );
};

export default Ord;
