import bakery_default from "./1.png";
import bakery_active from "./1-active.png";
import broadwalk_default from "./2.png";
import broadwalk_active from "./2-active.png";
import rice_default from "./4.png";
import rice_active from "./4-active.png";
import pranzo_default from "./6.png";
import pranzo_active from "./6-active.png";
import kobe_default from "./7.png";
import kobe_active from "./7-active.png";
import thuna_paha_default from "./8.png";
import thuna_paha_active from "./8-active.png";
import decadent_desserts_default from "./13.png";
import decadent_desserts_active from "./13-active.png";
import biriyani_sawan_default from "./20.png";
import biriyani_sawan_active from "./20-active.png";
import high_tea_decadance_default from "./21.png";
import high_tea_decadance_active from "./21-active.png";
import chinese_delight_default from "./25.png";
import chinese_delight_active from "./25-active.png";
import sandwitch_promo_default from "./28.png";
import sandwitch_promo_active from "./28-active.png";
import gift_vouchers_default from "./40.png";
import gift_vouchers_active from "./40-active.png";
import premium_maki_default from "./47.png";
import premium_maki_active from "./47-active.png";
import old_fashioned_pie_default from "./49.png";
import old_fashioned_pie_active from "./49-active.png";

export default {
  bakery: {
    default: bakery_default,
    active: bakery_active,
  },
  broadwalk: {
    default: broadwalk_default,
    active: broadwalk_active,
  },
  rice: {
    default: rice_default,
    active: rice_active,
  },
  pranzo: {
    default: pranzo_default,
    active: pranzo_active,
  },
  kobe: {
    default: kobe_default,
    active: kobe_active,
  },
  thuna_paha: {
    default: thuna_paha_default,
    active: thuna_paha_active,
  },
  decadent_desserts: {
    default: decadent_desserts_default,
    active: decadent_desserts_active,
  },
  biriyani_sawan: {
    default: biriyani_sawan_default,
    active: biriyani_sawan_active,
  },
  high_tea: {
    default: high_tea_decadance_default,
    active: high_tea_decadance_active,
  },
  chinese_delight: {
    default: chinese_delight_default,
    active: chinese_delight_active,
  },
  sandwitch_promo: {
    default: sandwitch_promo_default,
    active: sandwitch_promo_active,
  },
  gift_vouchers: {
    default: gift_vouchers_default,
    active: gift_vouchers_active,
  },
  premium_maki: {
    default: premium_maki_default,
    active: premium_maki_active,
  },
  old_fashioned: {
    default: old_fashioned_pie_default,
    active: old_fashioned_pie_active,
  },
};
