import "bootstrap/dist/css/bootstrap.min.css";
import { ModalContainer } from "components/common/ModalContainer";
import "font-awesome/css/font-awesome.min.css";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { metaService } from "services";
import { bannersListState } from "store/meta";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Index from "./components/Index";
import NotFound from "./components/NotFound";
import Event from "./components/Event";
import Ord from "./components/Ord";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import Error from "./components/Error";
import Success from "./components/Success";
import TrackOrder from "./components/TrackOrder";
import AuthGuard from "components/common/AuthGuard";


//
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-select2-wrapper/css/select2.css";

const App = (props) => {

  const setBanners = useSetRecoilState(bannersListState);

  function fetchData() {
    Promise.all([
      metaService.getBanners(),
    ]).then((data) => {
      if (data[0]) {
        setBanners(data[0]);
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ModalContainer />
      <ToastContainer
        progressStyle={{
          background: "rgb(41,128,185)",
          background:
            "linear-gradient(90deg, rgba(41,128,185,1) 0%, rgba(13,70,116,1) 60%)",
        }}
      />
      {props.location.pathname !== "/login" &&
        props.location.pathname !== "/register" && props.location.pathname !== "/resetpassword" ? (
        <Header />
      ) : (
        ""
      )}
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/404" exact component={NotFound} />
        <Route path="/terms-conditions" exact component={TermsConditions} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route
          path="/order-details/:orderId"
          exact
          component={AuthGuard.IsLoggedIn(TrackOrder)}
        />
        <Route path="/ord/:orderId" exact component={Ord} />
        <Route path="/event/:eventId" exact component={Event} />
        <Route path="/payment-success" exact component={Success} />
        <Route path="/payment-failed" exact component={Error} />
        <Route exact component={NotFound} />
      </Switch>
      {props.location.pathname !== "/login" &&
        props.location.pathname !== "/register" && props.location.pathname !== "/resetpassword" ? (
        <Footer />
      ) : (
        ""
      )}
    </>
  );
};

export default App;
