import { atom } from "recoil";

export const categoriesListState = atom({
  key: "categoriesListState",
  default: [],
});

export default {
  categoriesListState,
};
