import React, { useState, useEffect, Link, useRef } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useModalStore } from "store";
import SectionHeading from "./common/SectionHeading";
import ProductBox from "./home/ProductBox";
import TopSearch from "./home/TopSearch";
import ProductModal from "./modals/ProductModal";
import { useRecoilValue } from "recoil";
import { bannersListState } from "store/meta";
import { STORAGE_PATH } from "../constant"
import { BAND, TESTRECENTIMG, VIDEO } from "assets/img";
import EventsCarousel from "./common/EventsCarousel";
import Loading from "./common/Loading";

const Index = ({ history }) => {
  const showModal = useModalStore((state) => state.showModal);
  const banners = useRecoilValue(bannersListState);
  const [activeEvent, setActiveEvent] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const videoRef = useRef(null);

  useEffect(() => {
    // Play the video after the component has mounted
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  useEffect(() => {
    if (banners && banners.length > 0) {
      console.log("bannersbannersbannersbanners", banners)
      const specialPromos = banners.filter(banner => banner.status === 'ACTIVE');
      if (specialPromos && specialPromos.length > 0) {
        setCurrentEvents(specialPromos);
        setActiveEvent(specialPromos[0]);
      }
      const specialMenus = banners.filter(banner => banner.status === 'FUTURE');
      if (specialMenus && specialMenus.length > 0) {
        setUpcomingEvents(specialMenus);
      }
      const shortCutImgs = banners.filter(banner => banner.status === 'EXPIRED');
      if (shortCutImgs && shortCutImgs.length > 0) {
        setPastEvents(shortCutImgs);
      }
      setLoading(false);
      // const onPageLoad = banners.find(banner => banner.type === 'ON_PAGE_LOAD');
      // if(onPageLoad && onPageLoad.image){
      //   showModal(
      //     <ProductModal title="" image={STORAGE_PATH + "/" + onPageLoad.image}/>,
      //     { centered: true }
      //   );
      // }
    }
  }, [banners]);

  const handleMenuOnClick = (product) => {
    // if (product && product.open_type === 'POPUP') {
    //   showModal(
    //     <ProductModal title="Special Menu Cards" image={STORAGE_PATH + "/" + product.image} />,
    //     { centered: true }
    //   );
    // } else if (product && product.open_type === 'URL') {
    //   window.open(product.url);
    // }

    history.push(`/event/${product.id}`);
  };


  return (
    <>
      {loading ? (
        <Loading /> // Show loading component when loading is true
      ) : (
        <>
          {/* Render your content once data is ready */}
          <TopSearch />

          {currentEvents?.length > 0 && (
            <section className="section pt-5 pb-5 homepage-add-section" id="recent">
              <Container>
                <SectionHeading
                  heading=""
                  subHeading1="Step into the new year with elegance, joy, and celebration at Waters Edge! "
                  subHeading2="Join us for an enchanting evening in our grand ballroom, where the night will come alive with the electrifying sounds of the Marians Band. Dance all night long, indulge in a lavish buffet, and create unforgettable memories with your loved ones as we count down to midnight."
                  subHeading3={
                    <div >
                        🥂 Event Details: &nbsp;&nbsp;
                        <ul style={{ display: 'inline-grid', textAlign: 'left', paddingLeft: '1em', marginTop: '0.5em' }}>
                            <li>Date: 31st December 2024</li>
                            <li>Time: 8:00 PM onwards</li>
                            <li>Venue: Grand Ballroom, Waters Edge</li>
                        </ul>
                    </div>
                }
                  subHeading4="
Celebrate in style, savor every bite, and let the music and magic of the night fill your heart with excitement. Reserve your tickets today and welcome the new year with a night of joy and elegance at Waters Edge."
                  // subHeading4="🎟️ Buffet Passes: LKR 6,900 pp (Mon & Tue) | LKR 7,900 pp (Wed - Sun)"
                  // subHeading5="Don't miss out on the grandest celebration where laughter, exquisite brews, and euphoric melodies come together seamlessly. 🥨🎶"
                  className="fGold"
                />
                {/* <div
               className={`fGold fw-bold justify-content-center`} 
  dangerouslySetInnerHTML={{ __html: activeEvent.description }}
></div> */}
                <Row className="justify-content-center d-flex mt-5">
                  {currentEvents.map((menu, index) => {
                    return (
                      <Col md={12} xs={12} key={1}>

                        {/* <div className="pb-2">
                          <ProductBox
                            id={menu.id}
                            onClick={() => handleMenuOnClick(menu)}
                            image={STORAGE_PATH + "/" + menu.image}
                            imageClass="img-fluid rounded-lg shadow"
                            imageAlt="product"
                            linkUrl="#"
                            // title="Oktoberfest 2023"
                            // boxClass="rounded-lg shadow"
                          />
                        </div> */}
                        <Row className="d-flex align-items-stretch">
                          {/* <Col md={6} xs={12} className="d-flex">
                            <div className="pb-2 flex-grow-1 d-flex">
                              <video
                                ref={videoRef}
                                controls
                                autoPlay
                                loop
                                muted
                                className="img-fluid rounded-lg shadow w-100"
                                style={{ objectFit: 'cover', height: '100%' }}
                              >
                                <source src={VIDEO} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          </Col> */}
                          <Col md={12} xs={12} className="d-flex justify-content-center align-items-center">
                            <div className="pb-2 flex-grow-1 d-flex">
                              <ProductBox
                                id={1}
                                image={`${STORAGE_PATH}/${activeEvent.image}`}
                                onClick={() => handleMenuOnClick(menu)}
                                imageClass="img-fluid rounded-lg shadow w-100"
                                imageAlt="product"
                                linkUrl="#"
                                style={{ height: '100%' }}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <Row className="d-flex align-items-stretch justify-content-center ">
                          <Image style={{width: "100%"}} className="img-fluid" src={BAND} alt="Band" />
                        </Row> */}
                        <Row>
                          <Col md={12} className="d-flex justify-content-center mt-5">
                            <button
                              type="button"
                              onClick={() => handleMenuOnClick(menu)}
                              // className={`btn glowing-button ${disabled ? 'disabled' : 'btn-gold'}`} 
                              className='btn btn-gold btn-xl rounded-pill glowing-button font-weight-bold text-nowrap'
                            >
                              Grab Your Tickets Now
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>

              </Container>
            </section>
          )}

          {upcomingEvents?.length > 0 && (
            <section className="section pt-5 pb-5 homepage-add-section">
              <Container>
                <SectionHeading
                  heading="UPCOMING EVENTS"
                  subHeading=""
                />
                <Row>
                  {upcomingEvents.map((menu, index) => {
                    return (
                      <Col md={6} xs={12} key={index}>
                        <div className="pb-2">
                          <ProductBox
                            id={menu.id}
                            onClick={() => handleMenuOnClick(menu)}
                            image={STORAGE_PATH + "/" + menu.image}
                            imageClass="img-fluid rounded-lg shadow"
                            imageAlt="product"
                            linkUrl="#"
                          />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </section>
          )}

          {/* {pastEvents?.length > 0 && (
            <section className="section pt-5 pb-5 homepage-add-section">
              <Container>
                <SectionHeading heading="PAST EVENTS" subHeading="" />
                <Row className="justify-content-center">
                  <Col md={12}>
                    <div className="d-flex flex-wrap justify-content-start">
                      {pastEvents.map((menu, index) => (
                        <div 
                          className="card m-2 shadow-sm" 
                          key={index} 
                          style={{ flex: '1 1 200px', maxWidth: '200px', minHeight: '250px' }}>
                          <ProductBox
                            id={menu.id}
                            image={`${STORAGE_PATH}/${menu.image}`}
                            imageClass="card-img-top img-fluid rounded-lg"
                            imageAlt="product"
                            linkUrl="#"
                          />
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )} */}

          {pastEvents?.length > 0 && (
            <section className="section pt-5 pb-5 homepage-add-section">
              <Container>
                <SectionHeading heading="PAST EVENTS" subHeading="" />
                <Row className="justify-content-center mt-5">
                  <Col md={12}>
                    <EventsCarousel pastEvents={pastEvents} history={history} />
                  </Col>
                </Row>
              </Container>
            </section>
          )}

          {/* <section className="section pt-5 pb-5 homepage-add-section bg-white">
            <Container>
              <Row>
                {shortcuts.map((shortcut, index) => {
                  return (
                    <Col md={6} xs={12} key={index}>
                      <div className="pb-2">
                        <ProductBox
                          id={shortcut.id}
                          onClick={() => handleShortCutOnClick(shortcut)}
                          image={STORAGE_PATH + "/" + shortcut.image}
                          imageClass="img-fluid rounded-lg shadow"
                          imageAlt="product"
                          linkUrl="#"
                        />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section> */}
        </>
      )}

    </>
  );
};

const options = {
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
    1200: {
      items: 4,
    },
  },

  lazyLoad: true,
  pagination: false.toString(),
  loop: true,
  dots: false,
  autoPlay: 2000,
  nav: true,
  navText: [
    "<i class='fa fa-chevron-left'></i>",
    "<i class='fa fa-chevron-right'></i>",
  ],
};

export default withRouter(Index);
