import { toast } from "react-toastify";
import apiHandler from "./apiHandler";

export const login = async (data = { email: "", password: "" }) => {
  try {
    const response = await apiHandler.post("users/login", data);
    return response?.data?.data?.student;
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const register = async (
  data = {
    name: "",
    email: "",
    password: "",
    phone: "",
    confirmPassword: "",
  }
) => {
  try {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("phone", data.phone);
    formData.append("confirm_password", data.confirmPassword);

    const response = await apiHandler.post("users/register", formData);
    return response.data.data.user;
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const changePassword = async (
  data = {
    currentPassword: "",
    password: "",
    confirm: "",
  }
) => {
  try {
    const response = await apiHandler.put("users/password/update", data);
    toast("Password Updated");
    return response.data.data.student;
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const resetPassword = async (
  data = {
    phone_number: "",
    otp: "",
    password: "",
    confirm: "",
  }
) => {
  try {
    const response = await apiHandler.put("users/password/update/otp", data);
    toast("Password Updated");
    return response.data.data.student;
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const generateOTP = async (
  data = {
    phone_number: "",
  }
) => {
  try {
    const response = await apiHandler.post("users/password/reset/otp", data);
    toast("OTP code sent to your phone");
    return response.data.data.student;
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const verifyOTP = async (
  data = {
    phone_number: "",
    otp: "",
  }
) => {
  try {
    const response = await apiHandler.post("users/password/verify/otp", data);
    // toast("Send OTP Code");
    return response.data;
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const updateDetails = async (
  data = {
    name: "",
    email: "",
    phone: "",
  }
) => {
  try {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);

    const response = await apiHandler.put("users/update", formData);
    toast("User details updated!");
    return response.data.data;
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const getProfile = async () => {
  try {
    const response = await apiHandler.get("users/me");
    return response.data.data;
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const getLocations = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("user-locations/search", data);

    const locations = response.data.data.results.reduce((obj, item) => {
      obj[item.id] = {
        ...item,
      };

      return obj;
    }, {});
    return locations;
  } catch (error) {
    //
  }
};

export const createLocation = async (
  data = {
    city: "",
    longitude: "",
    latitude: "",
    tag: "",
    delivery_area: "",
    address_line_1: "",
    address_line_2: "",
    landmark: "",
  }
) => {
  try {
    const response = await apiHandler.post("user-locations/create", data);
    toast("Address Added Successfully!");
    return response.data.data;
  } catch (error) {
    //
  }
};

export const updateLocation = async (
  locationId = "",
  data = {
    city: "",
    longitude: "",
    latitude: "",
    tag: "",
    delivery_area: "",
    address_line_1: "",
    address_line_2: "",
    landmark: "",
  }
) => {
  try {
    const toastId = toast("Updating Address", { autoClose: false });
    const response = await apiHandler.put(
      `user-locations/update/${locationId}`,
      data
    );
    toast.update(toastId, {
      render: "Updating Address - Success!",
      autoClose: 1500,
    });
    return response.data.data;
  } catch (error) {
    //
  }
};

export const deleteLocation = async (locationId = "") => {
  try {
    const toastId = toast("Deleting Address", { autoClose: false });
    await apiHandler.delete(`user-locations/${locationId}`);
    toast.update(toastId, {
      render: "Deleting Address - Success!",
      autoClose: 1500,
    });
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const getPromocodes = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("user-promocodes/search", data);
    return response.data.data.results;
  } catch (error) {
    //
  }
};

export const addCard = async () => {
  try {
    const response = await apiHandler.post("user-cards/create");
    return response.data.responseData;
  } catch (error) {
    //
  }
};

export const getUserCards = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("user-cards/search", data);

    const cards = response.data.data.results.map((item) => {
      const payload = JSON.parse(item.token_response_payload);
      const expiry = payload.responseData.creditCard.expiry;
      const validity = `${expiry.substring(0, 2)}/20${expiry.substring(2, 5)}`;
      item["cardNumber"] = payload.responseData.creditCard.number;
      item["validity"] = validity;
      return item;
    });

    return cards;
  } catch (error) {
    //
  }
};

export const deleteUserCard = async (cardId = "") => {
  try {
    const toastId = toast("Deleting card", { autoClose: false });
    await apiHandler.delete(`user-cards/${cardId}`);
    toast.update(toastId, {
      render: "Deleting card - Success!",
      autoClose: 1500,
    });
  } catch (error) {
    //
  }
};

export const discount = async (
  data = {
    product_data: [],
    promocode: "",
  }
) => {
  try {
    const response = await apiHandler.post(
      "user-promocodes/discount/calculate",
      data
    );
    // toast("Send OTP Code");

    return response.data.data;
  } catch (error) {
    if(error?.response?.data?.errors && Object.values(error?.response?.data?.errors)){
      toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
    }else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export default {
  login,
  register,
  changePassword,
  resetPassword,
  generateOTP,
  verifyOTP,
  updateDetails,
  getProfile,
  createLocation,
  updateLocation,
  deleteLocation,
  getLocations,
  getPromocodes,
  getUserCards,
  addCard,
  deleteUserCard,
  discount,
};
