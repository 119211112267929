import React, { useEffect, useState } from "react";
import { Row, Col, Container, Image, InputGroup, Form, Button, Card } from "react-bootstrap";
import { orderService } from "services";
import { useRecoilState } from "recoil";
import { eventDetailState } from "store/order";
import { Link } from 'react-router-dom';
import { STORAGE_PATH } from "../constant"
import BankOffers from "./common/BankOffers";
import NoDataMessage from "./common/NoDataMessage";
import Icofont from "react-icofont";
import { toast } from "react-toastify";
import Loading from "./common/Loading";
import 'react-quill/dist/quill.snow.css'; // Import the styles
import SectionHeading from "./common/SectionHeading";

function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const Event = ({ match }) => {
    const [eventInfo, setEventInfo] = useRecoilState(eventDetailState);
    const [eventTickets, setEventTickets] = useState([]);
    const [eventDates, setEventDates] = useState([]);

    const [ipgType, setIpgType] = useState('default');

    const [purchTickets, setPurchTickets] = useState({});
    // const [selectedDate, setSelectedDate] = useState("Please select");
    const [totalTickets, setSTotalTickets] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0.00);
    const [discount, setDiscount] = useState(0.00);

    const [discPres, setDiscPres] = useState(0);

    const [cusName, setCusName] = useState("");
    const [cusPhone, setCusPhone] = useState("");
    const [cusEmail, setCusEmail] = useState("");
    const [cusNic, setNic] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState('Date not selected');
    const eventId = match.params.eventId;

    const [orderData, setOrderData] = useState();
    const [isChecked, setIsChecked] = useState(false);

    async function fetchData() {
        setPageLoading(true);
        window.scrollTo(0, 0);
        Promise.all([
            orderService.getEventDetails(eventId),
        ]).then((data) => {
            // setPageLoading(false);
            if (data[0] && data[0].status && data[0].status !== "ACTIVE") {
                window.location.href = "/";
                return;
            }
            setEventInfo(data[0]);

            setEventDates(data[0].dates && typeof data[0].dates == 'string' ? typeof JSON.parse(data[0].dates) == 'string' ? JSON.parse(JSON.parse(data[0].dates)) : JSON.parse(data[0].dates) : []);

            if (data[0]?.eventTicketMetas && data[0]?.eventTicketMetas.length > 0 && data[0]?.eventTickets && data[0]?.eventTickets.length > 0) {
                let tickPurchObj = {};

                let tickets2 = [];
                let tesat = data[0]?.eventTickets.forEach(element => {
                    if (element?.status == 'ACTIVE') {
                        tickets2.push(element)
                    }
                });

                let tickets = tickets2.map(ticket => {
                    tickPurchObj[ticket.id] = 0;
                    let found = data[0]?.eventTicketMetas.find(raw => raw.ticket_id == ticket.id);
                    return {
                        ...ticket,
                        ...found
                    };
                });

                setPurchTickets(tickPurchObj);

                setEventTickets(tickets);

                setTimeout(() => { setPageLoading(false) }, 1500)
            }
        }).catch((err) => {
            setPageLoading(false);
            setEventInfo(null);
        });
    }

    useEffect(() => {
        // Scroll to the top when pageLoading completes
        if (!pageLoading) {
            window.scrollTo(0, 0);
        }
    }, [pageLoading]);

    useEffect(() => {
        fetchData();
    }, [eventId]);

    useEffect(() => {
        console.log("purchTickets", purchTickets);
    }, [JSON.stringify(purchTickets)]);

    useEffect(() => {
        // console.log("eventTickets", eventTickets, eventDates);
    }, [JSON.stringify(eventTickets), JSON.stringify()]);


    const submitForm = async (values) => {
        if (cusPhone.trim() == "" || cusEmail.trim() == "" || cusNic.trim() == "" || cusName.trim() == "") {
            toast.error('Please enter valid data');
            return;
        }

        const phoneNumberPattern = /^\d{10}$/;
        if (phoneNumberPattern.test(cusPhone)) {
        } else {
            toast.error('Please enter valid phone number. Eg: 07XXXXXXXX');
            return;
        }

        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (emailPattern.test(cusEmail)) {
        } else {
            toast.error('Please enter valid email. Eg: tickets@watersedge.lk');
            return;
        }

        const lettersAndSpacesPattern = /^[a-zA-Z\s]+$/;
        if (lettersAndSpacesPattern.test(cusName)) {
        } else {
            toast.error('Please enter valid name');
            return;
        }

        const nicPattern = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/;
        if (nicPattern.test(cusNic)) {
        } else {
            toast.error('Please enter valid nic');
            return;
        }

        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (datePattern.test(selectedDate)) {
        } else {
            toast.error('Please valid date');
            return;
        }

        if (!purchTickets || !Object.values(purchTickets) || totalTickets == 0 || totalTickets < 0) {
            return;
        }

        setIsLoading(true);
        let tickets = [];
        Object.keys(purchTickets).map(purchTicket => {
            let found = eventTickets.find(ticket => ticket.ticket_id = purchTicket);

            if (purchTickets[purchTicket] && purchTickets[purchTicket] > 0) {
                tickets.push({
                    "ticket_id": Number(purchTicket),
                    "title": found?.title || '',
                    "quantity": purchTickets[purchTicket]
                });
            }
        });

        Promise.all([
            orderService.createOrder({
                "event_id": Number(eventId),
                "event_date": selectedDate,
                "ticket_purchases": tickets,
                "note": "",
                "cus_name": cusName,
                "cus_phone": cusPhone,
                "cus_email": cusEmail,
                "cus_nic": cusNic,
                "ipgType": ipgType
            }),
        ]).then((data) => {
            // console.log(data);
            setIsLoading(false);

            if (data?.[0]?.ipgResp?.responseData?.paymentPageUrl)
                window.location = data?.[0]?.ipgResp?.responseData?.paymentPageUrl;
            else {
                toast.error('Something went wrong!');
            }
            
            // if (data?.[0]) {
            //     setOrderData(data?.[0]);
            //     setTimeout(() => {
            //         setIsLoading(false);
            //         if (document.forms["ipgRedirectionForm2"]) {
            //             document.forms["ipgRedirectionForm2"].submit();
            //         }
            //     }, 1000);
            // }
        }).catch((err) => {
            setIsLoading(false);
        });
    };

    const handleRemove = (id) => {
        let purchTicketClone = purchTickets;
        let totalTicketsClone = totalTickets;
        let totalPriceClone = totalPrice;
        if (id && purchTicketClone[id] != undefined && purchTicketClone[id] > 0) {
            const foundTicket = eventTickets.find(ticket => ticket.ticket_id == id);
            if (foundTicket) {
                purchTicketClone[id] = purchTicketClone[id] - 1;
                totalTicketsClone = totalTicketsClone - 1;
                totalPriceClone = totalPriceClone - foundTicket.price;

            }
        }

        setTotalPrice(totalPriceClone);
        setSTotalTickets(totalTicketsClone);
        setPurchTickets(prevState => ({
            ...prevState,
            [id]: purchTicketClone[id]
        }));
    };

    const handleAdd = (id) => {
        let purchTicketClone = purchTickets;
        let totalTicketsClone = totalTickets;
        let totalPriceClone = totalPrice;
        let totalDiscount = discount;
        if (id && purchTicketClone[id] != undefined) {
            const foundTicket = eventTickets.find(ticket => ticket.ticket_id == id);
            if (foundTicket) {
                purchTicketClone[id] = purchTicketClone[id] + 1;
                totalTicketsClone = totalTicketsClone + 1;
                totalPriceClone = totalPriceClone + foundTicket.price;
            }
        }

        setTotalPrice(totalPriceClone);
        setSTotalTickets(totalTicketsClone);
        setPurchTickets(prevState => ({
            ...prevState,
            [id]: purchTicketClone[id]
        }));
    };

    const calcTotals = () => {
        let discountTemp = (totalPrice * discPres);
        setDiscount(discountTemp);
    }

    useEffect(() => {
        calcTotals();
    }, [discPres, totalPrice]);

    const handleChange = ({ target: { value } }) => {
        if (value === 'promo') {
            setIpgType('promo');
            setDiscPres(0.15);
        } else {
            setIpgType('default');
            setDiscPres(0);
        }
    };

    // const handleChange = ({ target: { value } }) => {
    //     console.log(value)
    //     setIpgType('promo');
    //     setDiscPres(0.15);
    // };

    // const handleChange2 = ({ target: { value } }) => {
    //     console.log(value)
    //     setIpgType(value)
    //     setDiscPres(0);
    // };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleDateSelection = (date) => {
        // console.log(purchTickets)
        // Reset all values in purchTickets to 0
        const resetTickets = Object.keys(purchTickets).reduce((acc, key) => {
            acc[key] = 0;
            return acc;
        }, {});

        // console.log(resetTickets); // This will log the reset object {21: 0, 22: 0}

        // Set total tickets and price to 0 since we're resetting the purchTickets
        let newTotalTickets = 0;
        let newTotalPrice = 0;

        // Update the states
        setPurchTickets(resetTickets);
        setSTotalTickets(newTotalTickets);
        setTotalPrice(newTotalPrice);

        setSelectedDate(date);
    };

    const checkDateSoldStatus = (date) => {
        // console.log(date);
        // console.log(eventTickets)

        const findTicket = eventTickets.find(row => row.event_date == date);

        if (findTicket && findTicket?.available_tickets == 0) {
            return true;
        }
        return false;

    };

    const checkDateExpStatus = (date) => {
        // console.log(date);
        // console.log(eventTickets)

        const findTicket = eventTickets.find(row => row.event_date == date);
        console.log("findTicket", findTicket);
        if (findTicket && findTicket?.status == "EXPIRED" || !findTicket) {
            return true;
        }
        return false;

    };

    const filteredTickets = eventTickets.filter(raw => raw.event_date && raw.event_date == selectedDate);

    const renderTickets = () => {
        if (eventTickets.length > 0) {
            return eventTickets.map((eventTicket, index) => (
                <Col key={index} md={6}>
                    <BankOffers
                        title={eventTicket.title}
                        logoImage={`${STORAGE_PATH}/${eventTicket.image}`}
                        imageclassName="card-img"
                        subTitle={eventTicket.description}
                        ticket_id={eventTicket.ticket_id}
                        price={`Rs. ${eventTicket.price}`}
                        eventTicket={eventTicket}
                        handleRemove={() => handleRemove(eventTicket.ticket_id)}
                        handleAdd={() => handleAdd(eventTicket.ticket_id)}
                        quantity={purchTickets[eventTicket.ticket_id]}
                    />
                </Col>
            ));
        }

        return (
            <Col>
                <NoDataMessage message="No passes available at the moment. Please check back later." />
            </Col>
        );
    };

    return (
        <>
            {/* https://apiwetickets.vision8labs.com/api/bookings/response-cybersource */}
            {/* <form
                method="post"
                action="https://testsecureacceptance.cybersource.com/pay"
                id="ipgRedirectionForm2"
                name="ipgRedirectionForm2"
            > */}
            <form
                method="post"
                action="https://secureacceptance.cybersource.com/pay"
                id="ipgRedirectionForm2"
                name="ipgRedirectionForm2"
            >
                <input type="hidden" name="access_key" value={orderData?.ipgResp?.access_key} />
                <input type="hidden" name="profile_id" value={orderData?.ipgResp?.profile_id} />
                <input type="hidden" name="transaction_uuid" value={orderData?.ipgResp?.transaction_uuid} />
                <input type="hidden" name="signed_field_names" value={orderData?.ipgResp?.signed_field_names} />
                <input type="hidden" name="unsigned_field_names" value={orderData?.ipgResp?.unsigned_field_names} />
                <input type="hidden" name="signed_date_time" value={orderData?.ipgResp?.signed_date_time} />
                <input type="hidden" name="locale" value={orderData?.ipgResp?.locale} />
                <input type="hidden" name="transaction_type" value={orderData?.ipgResp?.transaction_type} />
                <input type="hidden" name="reference_number" value={orderData?.ipgResp?.reference_number} />
                <input type="hidden" name="amount" value={orderData?.ipgResp?.amount} />
                <input type="hidden" name="currency" value={orderData?.ipgResp?.currency} />

                <input type="hidden" id="auth_trans_ref_no" name="auth_trans_ref_no" value="" />

                <input type="hidden" id="bill_to_forename" name="bill_to_forename" value={"cusName"} />
                <input type="hidden" id="bill_to_surname" name="bill_to_surname" value={"cusNameMJ"}
                />
                <input type="hidden" id="bill_to_address_line1" name="bill_to_address_line1" value="colombo"
                />
                <input type="hidden" id="bill_to_address_city" name="bill_to_address_city" value="colombo"
                />
                <input type="hidden" id="bill_to_address_country" name="bill_to_address_country" value="LK"
                />
                <input type="hidden" id="bill_to_email" name="bill_to_email" value="mayantha.j@vision8.io" />


                <input type="hidden" name="signature" value={orderData?.ipgResp?.signed2} />
            </form>
            {pageLoading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    {false ? (
                        <section className="section pt-5 pb-5 osahan-not-found-page">
                            <Container>
                                <Row>
                                    <Col md={12} className="text-center pt-5 pb-5 mt-5 mb-5">
                                        {/* <Image className="img-fluid" src="/img/404.png" alt="404" /> */}
                                        <h1 className="mt-2 mb-2 mt-5">Page not found</h1>
                                        <p>Uh-oh! Looks like the page you are trying to access, doesn't <br />exist. Please start afresh.</p>
                                        <Link className="btn btn-primary btn-lg" to="/">GO HOME</Link>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    ) : (
                        <>
                            <section className="section pb-2 ">

                                {eventInfo?.event_image && eventInfo?.event_image !== '' && (
                                    <Image className="img-fluid3" src={STORAGE_PATH + "/" + eventInfo?.event_image} alt={eventInfo?.title} />
                                )}

                                <Container>
                                    <Row className="pt-1">
                                        <Col md={12}>
                                            {/* <Card className="offer-card-horizontal border-0 shadow-sm mb-4">
                                                    <div className="card-body blck-background fGold">

                                                        <div dangerouslySetInnerHTML={{ __html: eventInfo?.description }} />
                                                    </div>
                                                </Card > */}
                                            <SectionHeading
                                                heading=""
                                                subHeading1="Step into the new year with elegance, joy, and celebration at Waters Edge! Join us for an enchanting evening in our grand ballroom, where the night will come alive with the electrifying sounds of the Marians Band. Dance all night long, indulge in a lavish buffet, and create unforgettable memories with your loved ones as we count down to midnight."

                                                subHeading2={
                                                    <div >
                                                        🥂 Event Details: &nbsp;&nbsp;
                                                        <ul style={{ display: 'inline-grid', textAlign: 'left', paddingLeft: '1em', marginTop: '0.5em' }}>
                                                            <li>Date: 31st December 2024</li>
                                                            <li>Time: 8:00 PM onwards</li>
                                                            <li>Venue: Grand Ballroom, Waters Edge</li>
                                                        </ul>
                                                    </div>
                                                }

                                                subHeading3="
Celebrate in style, savor every bite, and let the music and magic of the night fill your heart with excitement. Reserve your tickets today and welcome the new year with a night of joy and elegance at Waters Edge."
                                                // subHeading4="🎟️ Buffet Passes: LKR 6,900 pp (Mon & Tue) | LKR 7,900 pp (Wed - Sun)"
                                                // subHeading5="Don't miss out on the grandest celebration where laughter, exquisite brews, and euphoric melodies come together seamlessly. 🥨🎶"
                                                className="fGold"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="pt-5 px-4">
                                        <Col md={12}>
                                            <h6 className="fGold">Select Event Date:</h6>
                                        </Col>
                                    </Row>
                                    <Row className="pt-2 px-4">
                                        {eventDates && eventDates.length > 0 && eventDates.map((date, index) => (
                                            <Col key={index} md={3}>
                                                <div className="mt-3">
                                                    <div>
                                                        <Card
                                                            key={index}
                                                            className={`mr-2 ${checkDateSoldStatus(date) || checkDateExpStatus(date) ? 'date-card-disabled' : 'date-card'} ${selectedDate === date ? 'selected' : ''} `}
                                                            onClick={() => handleDateSelection(date)}
                                                        >
                                                            <Card.Body className="d-flex justify-content-center align-items-center text-center">
                                                                <Card.Title className="text-white mb-0 text-nowrap ">{formatDate(date)} {checkDateSoldStatus(date) && '(Sold Out)'}</Card.Title>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row className="mt-5 pt-2 mb-4 px-4">
                                        {
                                            purchTickets && filteredTickets && filteredTickets.length > 0 && filteredTickets.length == 1 && filteredTickets.map((eventTicket, index) => (
                                                <Col key={index} md={12}>
                                                    <BankOffers
                                                        title={eventTicket.title}
                                                        logoImage={`${STORAGE_PATH}/${eventTicket.image}`}
                                                        imageclassName="card-img"
                                                        subTitle={eventTicket.description}
                                                        isSold={eventTicket.available_tickets == 0}
                                                        ticket_id={eventTicket.ticket_id}
                                                        price={`Rs. ${eventTicket.price}`}
                                                        eventTicket={eventTicket}
                                                        handleRemove={() => handleRemove(eventTicket.ticket_id)}
                                                        handleAdd={() => handleAdd(eventTicket.ticket_id)}
                                                        quantity={purchTickets[eventTicket.ticket_id]}
                                                    />
                                                </Col>
                                            ))
                                        }
                                        {
                                            purchTickets && filteredTickets && filteredTickets.length > 0 && filteredTickets.length > 1 && filteredTickets.map((eventTicket, index) => (
                                                <Col key={index} md={6}>
                                                    <BankOffers
                                                        title={eventTicket.title}
                                                        logoImage={`${STORAGE_PATH}/${eventTicket.image}`}
                                                        imageclassName="card-img"
                                                        subTitle={eventTicket.description}
                                                        ticket_id={eventTicket.ticket_id}
                                                        price={`Rs. ${eventTicket.price}`}
                                                        eventTicket={eventTicket}
                                                        handleRemove={() => handleRemove(eventTicket.ticket_id)}
                                                        handleAdd={() => handleAdd(eventTicket.ticket_id)}
                                                        quantity={purchTickets[eventTicket.ticket_id]}
                                                    />
                                                </Col>
                                            ))
                                        }
                                        {/* {
                                                purchTickets && filteredTickets && filteredTickets.length == 0  && (
                                                    <Col md={6}>
                                                        <p>Ticket Not Available</p>
                                                    </Col>
                                                ) 
                                            } */}
                                    </Row>
                                    <Row className="pt-4 px-4">
                                        <Col md={6} xs={12}>

                                            <>
                                                {/* 
                                                    {eventDates && eventDates.length > 0 && (
                                                        <Form.Control
                                                            as="select"
                                                            name="event_date"
                                                            id="event_date"
                                                            placeholder="Event Date"
                                                            value={selectedDate}
                                                            onChange={(event) => setSelectedDate(event.target.value)}
                                                            className="fGold bg-transparent"
                                                        >
                                                            <option value={""}>Select Event Date</option>
                                                            {eventDates?.map((date, index) => (
                                                                <option key={index} value={date} disabled={date == '2023-10-13' || date == '2023-10-12' || date == '2023-10-14'}>
                                                                    {date}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    )} */}
                                                <Form.Control
                                                    name="cus_name"
                                                    id="cus_name"
                                                    type="text"
                                                    className="mt-2 fGold bg-transparent"
                                                    placeholder="Name"
                                                    onChange={(event) => setCusName(event.target.value)}
                                                    value={cusName}
                                                />
                                                <Form.Control
                                                    name="cus_phone"
                                                    id="cus_phone"
                                                    type="text"
                                                    className="mt-2 fGold bg-transparent"
                                                    placeholder="Mobile Number ( Eg: 07XXXXXXXX )"
                                                    onChange={(event) => setCusPhone(event.target.value.trim())}
                                                    value={cusPhone}
                                                />

                                                <Form.Control
                                                    name="cus_email"
                                                    id="cus_email"
                                                    type="text"
                                                    className="mt-2 fGold bg-transparent"
                                                    placeholder="Email"
                                                    onChange={(event) => setCusEmail(event.target.value.trim())}
                                                    value={cusEmail}
                                                />

                                                <Form.Control
                                                    name="cus_nic"
                                                    id="cus_nic"
                                                    type="text"
                                                    className="mt-2 fGold bg-transparent"
                                                    placeholder="NIC"
                                                    onChange={(event) => setNic(event.target.value.trim())}
                                                    value={cusNic}
                                                />
                                                {/* <div className="row d-flex align-items-center mt-5">
                                                    <Col lg={6} md={12} className="d-flex justify-content-center">
                                                        <label className={`font-weight-bold mb-3 fGold bank-card ${ipgType === 'default' ? 'selected-card' : ''}`} style={{ width: '100%', textAlign: 'center' }}>
                                                            <Form.Check
                                                                type="radio"
                                                                name="ipgType"
                                                                id="ipgType1"
                                                                key={1}
                                                                checked={ipgType === 'default'}
                                                                label="Visa/Master Card"
                                                                value="default"
                                                                onChange={handleChange}
                                                                className="d-none" 
                                                            />
                                                            <span>Visa/Master Card</span>
                                                        </label>
                                                    </Col>
                                                    <Col lg={6} md={12} className="d-flex justify-content-center">
                                                        <label className={`font-weight-bold mb-3 fGold bank-card ${ipgType === 'promo' ? 'selected-card' : ''}`} style={{ width: '100%', textAlign: 'center' }}>
                                                            <Form.Check
                                                                type="radio"
                                                                name="ipgType"
                                                                id="ipgType2"
                                                                key={2}
                                                                checked={ipgType === 'promo'}
                                                                label="HNB Credit Card 15% off"
                                                                value="promo"
                                                                onChange={handleChange}
                                                                className="d-none"
                                                            />
                                                            <span className="text-nowrap">HNB Credit Card 15% off</span> 
                                                        </label>
                                                    </Col>
                                                </div> */}
                                            </>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <Row className="pt-3">
                                                {/* <Col md={6} xs={12}>
                                            <Form.Check
                                                key={1}
                                                custom
                                                type="radio"
                                                name="ipgType"
                                                id="ipgType"
                                                checked={ipgType == 'promo'}
                                                label={'HNB Credit Card (15% OFF)'}
                                                value={'promo'}
                                                className="font-weight-bold mb-3 mr-3"
                                                onChange={handleChange}
                                            />
                                        </Col> */}
                                                {/* <Col md={12} xs={6}>
                                                        <div className="d-flex align-items-center ">
                                                            <Form.Check
                                                            type="radio"
                                                            name="ipgType"
                                                            id="ipgType1"
                                                            key={1}
                                                            checked={ipgType === 'default'}
                                                            label="Visa/Master Card"
                                                            value="default"
                                                            className={`font-weight-bold mb-3 mr-3 fGold d-inline-block bank-card ${ipgType === 'default' ? 'selected-card' : ''}`}
                                                            onChange={handleChange}
                                                            />
                                                            <Form.Check
                                                            type="radio"
                                                            name="ipgType"
                                                            id="ipgType2"
                                                            key={2}
                                                            checked={ipgType === 'promo'}
                                                            label="HNB Credit Card 15% off"
                                                            value="promo"
                                                            className={`font-weight-bold mb-3 mr-3 fGold d-inline-block bank-card ${ipgType === 'promo' ? 'selected-card' : ''}`}
                                                            onChange={handleChange}
                                                            />
                                                        </div>
                                                        </Col> */}
                                                <Col md={8} xs={6}>
                                                    <h5 className="font-weight-bold mt-0 mb-3 fGold">
                                                        Event Date
                                                    </h5>
                                                </Col>
                                                <Col md={4} xs={6}>
                                                    <h6 className="font-weight-bold mt-0 mb-3 fGold">
                                                        {`${selectedDate}`}
                                                    </h6>
                                                </Col>

                                                <Col md={8} xs={6}>
                                                    <h5 className="font-weight-bold mt-0 mb-3 fGold">
                                                        Total Passes
                                                    </h5>
                                                </Col>
                                                <Col md={4} xs={6}>
                                                    <h6 className="font-weight-bold mt-0 mb-3 fGold">
                                                        {`${totalTickets}`}
                                                    </h6>
                                                </Col>

                                                <Col md={8} xs={6}>
                                                    <h5 className="font-weight-bold mt-0 mb-3 fGold">
                                                        Subtotal
                                                    </h5>
                                                </Col>
                                                <Col md={4} xs={6}>
                                                    <h6 className="font-weight-bold mt-0 mb-3 fGold">
                                                        {`Rs. ${totalPrice}`}
                                                    </h6>
                                                </Col>

                                                <Col md={8} xs={6}>
                                                    <h5 className="font-weight-bold mt-0 mb-3 fGold">
                                                        Discount
                                                    </h5>
                                                </Col>
                                                <Col md={4} xs={6}>
                                                    <h6 className="font-weight-bold mt-0 mb-3 fGold">
                                                        {`Rs. ${discount}`}
                                                    </h6>
                                                </Col>

                                                <Col md={8} xs={6}>
                                                    <h5 className="font-weight-bold mt-0 mb-3 fGold">
                                                        Total to Pay
                                                    </h5>
                                                </Col>
                                                <Col md={4} xs={6}>
                                                    <h6 className="font-weight-bold mt-0 mb-3 fGold">
                                                        {`Rs. ${totalPrice - discount}`}
                                                    </h6>
                                                </Col>
                                                <Col md={12} xs={12} className="mt-2">
                                                    <label style={{ margin: '10px', color: 'white' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                        />&nbsp;
                                                        By clicking "Proceed to Pay" you agree to the{' '}
                                                        <a href="/terms-conditions" target="_blank" rel="noopener noreferrer">
                                                            terms and conditions
                                                        </a>.
                                                    </label>
                                                </Col>
                                                <Col md={12} xs={12} className="text-left">
                                                    <div className="brands py-4 d-flex flex-column flex-md-row align-items-center justify-content-start ">
                                                        <Image
                                                            style={{ maxWidth: 40 }}
                                                            className="img-fluid mb-2 mr-5 mb-md-0 me-md-3"
                                                            src="/img/visa-secure_blu_2021.png"
                                                            alt="visa"
                                                        />
                                                        <Image
                                                            style={{ maxWidth: 125, backgroundColor: 'white' }}
                                                            className="img-fluid"
                                                            src="/img/mc_idcheck_hrz_rgb_pos.png"
                                                            alt="master"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12} xs={12}>
                                                    <InputGroup.Append>
                                                        <Button
                                                            id="button-addon2"
                                                            className="btn-pay"
                                                            type="submit"
                                                            onClick={submitForm}
                                                            disabled={isLoading || totalTickets == 0 || !selectedDate || selectedDate == "Please select" || !cusName || cusName == "" || !cusPhone || cusPhone == "" || !cusEmail || cusEmail == "" || !cusNic || cusNic == "" || !isChecked}
                                                        >
                                                            <Icofont icon="long-arrow-right" /> PROCEED TO PAY
                                                        </Button>
                                                    </InputGroup.Append>
                                                </Col>

                                            </Row>

                                        </Col>

                                    </Row>

                                </Container>
                            </section>

                            <section className="section pb-5 ">
                                <Container>
                                    {eventInfo?.plan_img && eventInfo?.plan_img !== '' && (
                                        <Row className="pt-1 pb-1">
                                            <Col xs={12}>
                                                <Image className="img-fluid2" src={STORAGE_PATH + "/" + eventInfo?.plan_img} alt={eventInfo?.title} />
                                            </Col>
                                        </Row>
                                    )}

                                    {eventInfo?.parking_plan_img && eventInfo?.parking_plan_img !== '' && (
                                        <Row className="pt-4">
                                            <Col xs={12}>
                                                <Image className="img-fluid2" src={STORAGE_PATH + "/" + eventInfo?.parking_plan_img} alt={eventInfo?.title} />
                                            </Col>
                                        </Row>
                                    )}
                                </Container>
                            </section>
                        </>
                    )}



                </>
            )}
        </>

    );
};

export default Event;
