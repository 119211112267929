import { atom } from "recoil";

export const orderState = atom({
  key: "orderState",
  default: {},
});

export const orderDetailState = atom({
  key: "orderDetailState",
  default: {},
});

export const eventDetailState = atom({
  key: "eventDetailState",
  default: {},
});


export default {
  orderState,
  orderDetailState,
  eventDetailState
};
