import { LOGO, USER } from "assets/img";
import qs from "qs-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Badge,
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
} from "react-bootstrap";
import Icofont from "react-icofont";
import { Link, NavLink, withRouter } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { cartState, cartTotalState } from "store/product";
import { authState, userState } from "store/user";
import CartDropdownItem from "../cart/CartDropdownItem";
import DropDownTitle from "../common/DropDownTitle";
import { resetRecoil } from 'recoil-nexus'

const Header = ({ history }) => {
  const [isNavExpanded, setNavExpanded] = useState(false);
  let [cart, setCart] = useRecoilState(cartState);
  const isAuthenticated = useRecoilValue(authState);
  const resetUserState = useResetRecoilState(userState);
  const cartTotal = useRecoilValue(cartTotalState);

  const node = useRef(null);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // if clicked inside menu do something
    } else {
      // If clicked outside menu, close the navbar.
      setNavExpanded(false);
    }
  };

  const handleCloseMenu = () => {
    setNavExpanded(false);
  };

  const handleRemoveCartItem = (itemId) => {
    let clone = JSON.parse(JSON.stringify(cart));

    delete clone[itemId];
    setCart({ ...clone });
  };

  const handleLogout = () => {
    localStorage.removeItem("access-token");
    resetRecoil(userState);
    history.push("/");
    resetUserState();
  };

  //   const getSubTotal = () => {
  //     const subTotal = Object.values(cart).reduce((total, item) => {
  //       return total + item.total;
  //     }, 0);

  //     return round(subTotal, 2);
  //   };

  const isCartEmpty = useMemo(() => {
    return Object.keys(cart).length === 0;
  }, [cart]);

  const cartItemsCount = useMemo(() => {
    return Object.keys(cart).length;
  }, [cart]);

  const handleLoginAndCheckout = () => {
    const query = qs.stringify({ to: "checkout" });
    history.replace({ pathname: "/login", search: query });
  };

  useEffect(() => {
    window.addEventListener("click", handleClick, false);
    return () => {
      window.removeEventListener("click", handleClick, false);
    };
  }, []);

  return (
    <div ref={node}>
      {/* <Navbar
        onToggle={setNavExpanded}
        expanded={isNavExpanded}
        color="light"
        expand="lg"
        className="navbar-light osahan-nav shadow-sm"
      >
        <Container style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Navbar.Brand to="/">
            <Image src={LOGO} alt="" style={{ height: 100 }} />
          </Navbar.Brand>

          <Navbar.Toggle />

          <Navbar.Collapse id="navbarNavDropdown">
            <Nav className="ml-auto" onSelect={handleCloseMenu}>
              <Nav.Link
                eventKey={0}
                as={NavLink}
                activeclassname="active"
                exact
                to="/"
              >
                Home <span className="sr-only">(current)</span>
              </Nav.Link>
              <Nav.Link
                eventKey={1}
                as={NavLink}
                activeclassname="active"
                to="#recent"
              >
                Recent Events{" "}
              </Nav.Link>
              <Nav.Link
                eventKey={2}
                as={NavLink}
                activeclassname="active"
                to="/offers"
              >
                <Icofont icon="sale-discount" /> Offers{" "}
                <Badge variant="danger">New</Badge>
              </Nav.Link>

              {isAuthenticated ? (
                <NavDropdown
                  alignRight
                  title={
                    <DropDownTitle
                      className="d-inline-block"
                      image={
                        "https://secure.gravatar.com/avatar/d6cdae91b2668c96b73831cc29da0bcb?s=60"
                      }
                      imageAlt="user"
                      imageClass="nav-osahan-pic rounded-pill"
                      title="My Account"
                    />
                  }
                >
                  <NavDropdown.Item
                    eventKey={3.1}
                    as={NavLink}
                    activeclassname="active"
                    to="/myaccount/orders"
                  >
                    <Icofont icon="food-cart" /> Orders
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey={3.2}
                    as={NavLink}
                    activeclassname="active"
                    to="/myaccount/offers"
                  >
                    <Icofont icon="sale-discount" /> Offers
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey={3.3}
                    as={NavLink}
                    activeclassname="active"
                    to="/myaccount/favourites"
                  >
                    <Icofont icon="heart" /> Favourites
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey={3.4}
                    as={NavLink}
                    activeclassname="active"
                    to="/myaccount/payments"
                  >
                    <Icofont icon="credit-card" /> Cards
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey={3.5}
                    as={NavLink}
                    activeclassname="active"
                    to="/myaccount/addresses"
                  >
                    <Icofont icon="location-pin" /> Addresses
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey={3.6}
                    as={NavLink}
                    activeclassname="active"
                    to="/myaccount/settings"
                  >
                    <Icofont icon="settings-alt" /> Settings
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey={3.7}
                    as={NavItem}
                    activeclassname="active"
                    onSelect={handleLogout}
                  >
                    <Icofont icon="user" /> Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link
                  eventKey={10}
                  as={NavLink}
                  activeclassname="active"
                  to="/login"
                >
                  Login
                </Nav.Link>
              )}

              <NavDropdown
                activeclassname="active"
                alignRight
                className="dropdown-cart"
                title={
                  <DropDownTitle
                    className="d-inline-block"
                    faIcon="shopping-basket"
                    iconClass="mr-1"
                    title="Cart"
                    badgeClass="ml-1"
                    badgeVariant="success"
                    badgeValue={cartItemsCount}
                  />
                }
              >
                <div className="dropdown-cart-top shadow-sm">
                  <div className="dropdown-cart-top-body border-top p-4">
                    {isCartEmpty ? <p>Cart is Empty</p> : null}
                    {Object.keys(cart).map((itemId, index) => {
                      const item = cart[itemId];
                      return (
                        <CartDropdownItem
                          key={index}
                          id={itemId}
                          icoIcon="ui-press"
                          iconClass="text-success food-item"
                          title={`${item.name} x ${item.quantity}`}
                          price={item.total}
                          optionName={item.optionName}
                          potionName={item.potionName}
                          onRemove={handleRemoveCartItem}
                        />
                      );
                    })}
                  </div>
                  <div className="dropdown-cart-top-footer border-top p-4">
                    <p className="mb-0 font-weight-bold text-secondary">
                      Sub Total{" "}
                      <span className="float-right text-dark">
                        Rs. {cartTotal}
                      </span>
                    </p>
                    <small className="text-info">Extra charges may apply</small>
                  </div>
                  <div className="dropdown-cart-top-footer border-top p-2">
                    {isAuthenticated ? (
                      <NavDropdown.Item
                        as={Link}
                        eventKey={5.1}
                        className="btn btn-success btn-block py-3 text-white text-center dropdown-item"
                        to="/checkout"
                        disabled={isCartEmpty}
                      >
                        {" "}
                        Checkout
                      </NavDropdown.Item>
                    ) : (
                      <NavDropdown.Item
                        eventKey={5.1}
                        className="btn btn-success btn-block py-3 text-white text-center dropdown-item"
                        to="/login"
                        onSelect={handleLoginAndCheckout}
                      >
                        {" "}
                        Login and Checkout
                      </NavDropdown.Item>
                    )}
                  </div>
                </div>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
    </div>
  );
};
export default withRouter(Header);
