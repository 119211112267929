import React from "react";
import { NavLink, Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Badge,
  Button,
  Breadcrumb,
  Alert,
} from "react-bootstrap";

const TermsConditions = () => {
  return (
    <section className="section pt-5 pb-5">
      <Container>
        <Row>
          <Col md={8} className="mx-auto bg-white p-5 rounded">
            <h2>Privacy Policy</h2>
            <hr />
            <p>
            This Privacy Policy governs the manner in which reservations.watersedge.lk collects, uses, maintains and discloses information collected from users (each, a “User”) of the reservations.watersedge.lk / website (“Site”). This privacy policy applies to the Site and all products and services offered by the reservations.watersedge.lk International (Pvt) Ltd.
            </p>
            <p>
            We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
            </p>
            <p>
            Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TermsConditions;
